.language-switcher {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .language-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  