/* Add this at the top of your App.css */
@font-face {
    font-family: 'LastRites';
    src: url('../../fonts/LastRites.ttf') format('truetype');
  }
  
  .text-container {
    font-family: 'LastRites', Arial, sans-serif; 
    max-width: 800px; /* Limit the width of the text */
    margin: 20px auto; /* Vertically space the text, and center it horizontally */
    padding: 20px; /* Apply some space around the text */
    text-align: justify;
    line-height: 1.6; /* Improve readability by increasing line height */
    color: white;
    margin-top: -100px;
    
  }
  
  /* Apply different styles when the screen size is 600px or less */
@media (max-width: 600px) {
    .logo-container {
      max-width: 300px;
      padding-top: 30%;
      margin-top: 50px;
    }
  
    .logo {
      background-size: contain;
      background-position: center;
    }
  
    .text-container {
      margin-top: 20px; /* Adjusted for smaller screen size */
    }
  }
  