.App {
  text-align: center; /* This will center the logo container */

}

@font-face {
  font-family: 'LastRites';
  src: url('./fonts/LastRites.ttf') format('truetype');
}

.logo-container {
  width: 100%; /* This will make the container take the full width of its parent */
  max-width: 600px; /* Set a max-width to prevent the logo from being too large on big screens */
  height: 0;
  padding-top: 50%; /* This is a trick to maintain aspect ratio */
  position: relative; /* This will allow us to absolute position the logo */
  margin: 0 auto 0 auto; /* This will center the logo container */
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain; /* This will ensure the logo scales to fit the container */
  background-repeat: no-repeat;
  background-position: center; /* This will center the logo within the container */
 
}

/* Apply different styles when the screen size is 600px or less */
@media (max-width: 600px) {
  .logo-container {
    /* You might want to reduce the max-width and padding-top values here to make the logo smaller on mobile */
    max-width: 300px;
    padding-top: 30%;
  }

  .logo {
    /* You could adjust the background-size and position properties here if needed to fine-tune the logo's appearance on mobile */
    background-size: contain;
    background-position: center;
  }
}